import React, { useEffect, useLayoutEffect } from "react"
import Layout from "../components/layout"
import sliderImage from "../images/glowna/01.jpg"
import sliderImageMobile from "../images/m_01.jpg"
import sliderImageSmall from "../images/m_01b.jpg"
import ScrollBlock from "../components/scrollBlock"
import RoutesSection from "../components/RoutesSection"
import LinkBox from '../components/LinkBox';
import Banner from "../components/Banner";
import Gallery from '../components/Gallery';
import SubBanner from '../components/fleet/SubBanner'
import Details from "../components/fleet/Details"
import Boxes from "../components/fleet/Boxes"
import { useTranslation, Trans } from "react-i18next"

const FleetPage = (props) => {
  const { t, i18n } = useTranslation()
  if (i18n.language !== "en") i18n.changeLanguage("en")
  useLayoutEffect(() => {})
  return (
    <Layout props={props}>
      <div className="slider relative bg-black">
        <Banner
          sliderImage={sliderImage}
          sliderImageMobile={sliderImageMobile}
          sliderImageSmall={sliderImageSmall}
          title01={t("about.our")}
          title02={t("about.fleet")}
          subtitle={t("general.110sets")}
          id="banner"
          link1={t("general.fleetHref")}
          link2={t("general.contactHref")}
        ></Banner>
        <ScrollBlock></ScrollBlock>
      </div>
      <SubBanner />
      <Details />
      <Boxes />
      <RoutesSection></RoutesSection>
      <section id="gallery">
        <h1 className="h1 section-title uppercase text-center font-light">
          {t("general.gallery")}{" "}
          <span className="font-bold">{t("general.photos")}</span>
        </h1>
        <Gallery></Gallery>
      </section>
      <section>
        <div className="flex flex-wrap">
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/en/about/"
              cssClass="about"
              text={t("general.about")}
            ></LinkBox>
          </div>
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/en/contact/"
              cssClass="contact"
              text={t("general.contact")}
            ></LinkBox>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default FleetPage;